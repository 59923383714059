import React, { Component } from 'react';
import Menu from 'antd/es/menu'
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';

import BannerAnim from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import { EditorState,convertFromRaw,convertToRaw } from 'draft-js';
import * as func from './func';
import App from './App';

import store from './store';

import 'rc-banner-anim/assets/index.css';
const { Element,  Thumb } = BannerAnim;
const BgElement = Element.BgElement

class MainInfo extends Component {
    constructor(props) {
        
        super(props);
       
        this.state = {
                tosuspend:false,
                propsSecc1:'',
                propsSecc2:'',
                propsSecc3:'',
                propsSecc4:'',
                propsSecc5:'',
                mount_inc:true,
                IncMen:['0'],
                end:false,
                intShow: 0,
                prevEnter: false,
                nextEnter: false,
                thumbEnter: false,
                XautoPlay:true,
                displayBanner:true,
                imgArray : [
                    'seccion1',
                    'seccion5'
                  ]


        }
        this.child = React.createRef();
        this.banner = React.createRef();
        store.subscribe(() => {
            var tmp1='',tmp2='',tmp3='',tmp4='',tmp5='';
            try{
            if (store.getState().propsSecc1!=='') tmp1=EditorState.createWithContent(convertFromRaw(JSON.parse(store.getState().propsSecc1))).getCurrentContent();
            if (store.getState().propsSecc2!=='') tmp2=EditorState.createWithContent(convertFromRaw(JSON.parse(store.getState().propsSecc2))).getCurrentContent();
            if (store.getState().propsSecc3!=='') tmp3=EditorState.createWithContent(convertFromRaw(JSON.parse(store.getState().propsSecc3))).getCurrentContent();
            if (store.getState().propsSecc4!=='') tmp4=EditorState.createWithContent(convertFromRaw(JSON.parse(store.getState().propsSecc4))).getCurrentContent();
            if (store.getState().propsSecc5!=='') tmp5=EditorState.createWithContent(convertFromRaw(JSON.parse(store.getState().propsSecc5))).getCurrentContent();
            }catch(err){ 
                console.log('Error en la informacion')
            }

            this.setState({
                mount_inc:store.getState().mount_inc,
                IncMen:store.getState().IncMen,
                displayBanner:store.getState().displayBanner,
                propsSecc1:tmp1,
                propsSecc2:tmp2,
                propsSecc3:tmp3,
                propsSecc4:tmp4,
                propsSecc5:tmp5,
            })
        });
    }
    ClickMenu=({key})=>{
        switch (key){
            case '4':
                return 
            default:    
            this.child.current.setChangeTabs("2")
        }
      
       
    }
    clickBcktp=()=>{
        store.dispatch({ type: 'INIMENU' });
    }
    componentDidMount=async ()=>{
        func.queryvieWebSiteAllFn()
        const tosuspend = await  func.statusColletion()
        if (!!tosuspend){
            window.location.replace('http://qqra.net/suspend/index.html')
        }
        this.setState({tosuspend:!!tosuspend})
    }
    getNextPrevNumber=()=> {
        let nextInt = this.state.intShow + 1;
        let prevInt = this.state.intShow - 1;
        if (nextInt >= this.state.imgArray.length) {
          nextInt = 0;
        }
        if (prevInt < 0) {
          prevInt = this.state.imgArray.length - 1;
        }
    
        return [prevInt, nextInt];
      }
      onChange=(type, int)=> {
        if (type === 'before') {
            this.setState({
            intShow: int,
            });
        }
    }
    onMouseEnter=()=> {
        this.setState({
          thumbEnter: true,
        });
      }
    
      onMouseLeave=()=> {
        this.setState({
          thumbEnter: false,
        });
      }
    render() {
        var {imgArray,XautoPlay,displayBanner,tosuspend}=this.state;
        // const intArray = this.getNextPrevNumber();
        const thumbChildren = imgArray.map((img, i) =>
            <span key={i}><i  className={`${img}`} /></span>
            );
        return (
        <div>
        {tosuspend?"":
        <Row >
                <Col>
                    <Row className="MenuPrim">
                       <Col className="boxHead_p2">
                            <label style={styles.textTitulo1}></label><label style={styles.textTitulo2}></label>
                        </Col>
                        <Col className="MenuPrim_Nivel2">
                            <Row type="flex" justify="end">
                                <Col>
                                    <Menu
                                        mode="horizontal"
                                        theme="dark"
                                        onClick={(e)=>this.ClickMenu(e)}
                                        >
                                         <Menu.Item key="4" className="MenuItemFVP">
                                            <span className="MenuItemF1">
                                                <a href='https://ver.pollacasagrande.com'>
                                                VER POLLAS
                                                </a>
                                            </span>
                                        </Menu.Item>
                                        <Menu.Item key="5" className="MenuItemF">
                                            <span className="MenuItemF1">
                                                REGISTRATE
                                            </span>
                                        </Menu.Item>
                                    </Menu>
                                </Col>
                            </Row>
                        </Col>
                          
                    </Row>
                </Col>
                <Col>
                    <App ref={this.child} />
                </Col> 
                <Col className="bodyBanner-row" style={{display:(displayBanner?'':'none')}}>
                    <BannerAnim 
                        type={['verticalOverlay', 'vertical', 'gridBar','grid',]}
                        onChange={this.onChange}  
                        prefixCls="custom-arrow-thumb"
                        className="bodyBanner"
                        autoPlay={XautoPlay}
                        autoPlaySpeed={10000}
                        ref={(c) => { this.banner = c; }}
                        >
                        <Element key="aaa"
                        prefixCls="banner-user-elem"
                        >

                        <BgElement
                            key="bg"
                            className="seccion1"
                            style={{position: 'relative'}}
                        />
                        <TweenOne   
                            className="banner-user-text "  
                            style={{position: 'absolute'}}
                            animation={{ y: 30, opacity: 0, type: 'from' }}>
                            {this.state.propsSecc1!==''?ReactHtmlParser(draftToHtml(convertToRaw(this.state.propsSecc1))):''}
                        </TweenOne>
                        </Element>
                        {/* <Element key="bbb"
                        prefixCls="banner-user-elem"
                        >
                        <BgElement
                            key="bg"
                            className="seccion2"
                        
                            style={{position: 'relative'}}
                        />
                        <TweenOne 
                            className="banner-user-text" 
                            style={{position: 'absolute'}}
                            animation={{ y: 30, opacity: 0, type: 'from' }}>
                            {this.state.propsSecc2!==''?ReactHtmlParser(draftToHtml(convertToRaw(this.state.propsSecc2))):''}
                        </TweenOne>
                        </Element>
                        <Element key="ccc"
                        prefixCls="banner-user-elem"
                        >
                        <BgElement
                            key="bg"
                            className="seccion3"

                            style={{position: 'relative'}}
                        />
                        <TweenOne 
                            className="banner-user-text" 
                            style={{position: 'absolute'}}
                            animation={{ y: 30, opacity: 0, type: 'from' }}>
                        {this.state.propsSecc3!==''?ReactHtmlParser(draftToHtml(convertToRaw(this.state.propsSecc3))):''}
                        </TweenOne>
                        </Element>
                        <Element key="ddd"
                        prefixCls="banner-user-elem"
                        >
                        <BgElement
                            key="bg"
                            className="seccion4"

                            style={{position: 'relative'}}
                        />
                        <TweenOne 
                            className="banner-user-text" 
                            style={{position: 'absolute'}}
                            animation={{ y: 30, opacity: 0, type: 'from' }}>
                        {this.state.propsSecc4!==''?ReactHtmlParser(draftToHtml(convertToRaw(this.state.propsSecc4))):''}
                        </TweenOne>
                        </Element> */}
                        <Element key="eee"
                        prefixCls="banner-user-elem"
                        >
                        <BgElement
                            key="bg"
                            className="seccion5"
                            style={{position: 'relative'}}
                        />
                        <TweenOne 
                            className="banner-user-text" 
                            style={{position: 'absolute'}}
                            animation={{ y: 30, opacity: 0, type: 'from' }}>
                            {this.state.propsSecc5!==''?ReactHtmlParser(draftToHtml(convertToRaw(this.state.propsSecc5))):''}
                        </TweenOne>
                        </Element>

                        <Thumb 
                            prefixCls="user-thumb" 
                            key="thumb" 
                            component={TweenOne}
                            animation={{ bottom: 0 }}
                        >
                            {thumbChildren}
                        </Thumb>
                        </BannerAnim>
                </Col>
        </Row>}
        </div>

    )
}


}

const styles={
    textTitulo1:{
        fontSize: '30px',
        color: '#FF5733',
        fontWeight: '600',
    },
    textTitulo2:{
        fontSize: '20px',
        color: 'green',
    }
    }

export default MainInfo;