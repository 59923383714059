import React, { Component } from 'react';
import Col from "antd/es/col";
import Row from "antd/es/row";
import CloudnacResulPolla from './cloudnacResulPolla';
import   './cloudnacResulPolla.scss';

class Mainpolla extends Component {
    render() {
        return (
            <Row>
                <Col xs={[15,20]} lg={24} className="frm-col-Polla">
                    <CloudnacResulPolla />
                </Col>
            </Row>
        );
    }
}

export default Mainpolla;