import React, { Component } from "react";
import FormattedNumber from './component/FormatNumber';
import Spin from "antd/es/spin";
import Affix from "antd/es/affix";
import Drawer from "antd/es/drawer";
import Popover from "antd/es/popover";
import Layout from "antd/es/layout";
import Menu from "antd/es/menu";
import Input from "antd/es/input";
import Table from "antd/es/table";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import Modal from "antd/es/modal";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Badge from "antd/es/badge";
import Avatar from "antd/es/avatar";
import List from "antd/es/list";
import notification from "antd/es/notification";
import "./Mainapp.scss";
import {querycallsaldoFn,queryrsmJndLtFn,mutationRegistrarApuestaFn} from "./func";
import {url} from "./globals.js";
import Cloudnacionales from "./cloudnacionales";
import CloudmonitorPolla from "./cloudmonitorPolla";
import CloudSelecPolla from "./cloudSelecPolla";
import CloudRecomePolla from "./cloudpollaRecome";
import Cloudmisticket from "./cloudmisticket";
import Cloudmisdatos from "./cloudopciones";
import CloudTransacc from "./iuTransacc";
import CloudnacResumidoResul from "./cloudnacResumidoResul";
import CloudReportes from "./cloudreporte";
import CloudVerticket from "./cloudVerticket";
import { line1 } from "./genViewTk";
import CloudScoreNacforCarr from "./cloudnacResulforCarr";
import {hashSet} from './services/general'
import Whatsapp from './whatsapp/';


import store from "./store";

import PrintTk from "./isPrintTk";

var _ = require("lodash");
var ip = require("ip");
var moment = require("moment");
// let socket=null
// fetch(chat).then(()=>{
//   socket = socketIOClient(chat);
// }).catch(()=>console.log('Error in Chat NOT Connect'))


const { Header, Content, Sider } = Layout;
const { TextArea } = Input;
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_849701_8eluroeanb9.js", // generated by iconfont.cn
});

class Mainapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketMensaje:'',
      whatsapp:false,
      receptor: false,
      portImp: "",
      paramterImp: "",
      tipoprinter: 1,
      verBtnTk: false,
      tipoParlay: 1,
      loading: false,
      ppBoxIm: 0,
      currentSub2: ["1"],
      opviewTK: -1,
      subopview: 0,
      displayAp: false,
      displayAp1: false,
      collapsed: true,
      id: "12345",
      iu: "Angel Granadillo",
      unidad: "$",
      moneda: "Bss",
      saldo: "100",
      avatar: "0",
      imageHash: Date.now(),
      pago: 1,
      tipo: 0,
      titulo: "",
      tituloIU: "",
      context: "",
      contextPlay: "",
      contextIU: "",
      ModeRedux: false,
      context_modal: "",
      visible: false,
      activoView: {},
      miTicket: [],
      miTicketAmericana: [],
      miTicketNacionales: [],
      miTicketLoteria: [],
      logros: [],
      americana: [],
      nacionales: [],
      loteria: [],
      acodeDB: [],
      displayTk: "",
      apuesta: 0,
      premioParley: 0,
      serialImp: 0,
      visibleprint: false,
      vsbprt: false,
      activoLogin: 0,
      impriTK: 0,
      stCol: false,
      statePross: moment().unix(),
      stateBtn: false,
      stateAp: false,
      tm: null,
      logonNo: false,
      visibleDrawer: false,
      blockesc: true,
      url,
      historiCht: [],
      statusCht: [],
      messgCht: "",
      cantMsg: 0,
      ntf_ticket: false,
      visPreapor: false,
      valuePreapro: [],
      habiNacionales: [],
      access: [],
      motivoOBS: [
        { id: 1, color: "volcano", text: "Sorteo Cerrado" },
        { id: 2, color: "geekblue", text: "Cupo Maximo" },
        { id: 3, color: "green", text: "Monto Modificado" },
      ],
      colums_noti: [
        { title: "Numeros", dataIndex: "numeros", key: "numeros" },
        { title: "Apuesta", dataIndex: "monto", key: "monto" },
        { title: "Loteria", key: "loteria", dataIndex: "loteria" },
        { title: "Sorteo", key: "sorteo", dataIndex: "sorteo" },
        {
          title: "Motivo",
          key: "Motivo",
          dataIndex: "motivo",
          render: (tags) => (
            <span>
              <Tag color={this.state.motivoOBS[tags].color} key={tags}>
                {this.state.motivoOBS[tags].text}
              </Tag>
            </span>
          ),
        },
      ],
      data_noti: [],
    };
    this.child = React.createRef();
    store.subscribe(() => {
      this.setState({
        saldo:store.getState().saldo,
        blockesc: store.getState().blockesc,
        apuesta: store.getState().apuesta,
        activoView: store.getState().activoView,
        ppBoxIm: store.getState().ppBoxIm,
        vsbprt: store.getState().vsbprt,
      });
    });
    this.querycallsaldoFn = querycallsaldoFn.bind(this)
    this.hashSet = hashSet.bind()
    this.queryrsmJndLtFn = queryrsmJndLtFn.bind(this);
    this.line = line1.bind(this);
    this.mutationRegistrarApuestaFn = mutationRegistrarApuestaFn.bind(this);
  }

  thisuseUpdateData=(data)=>{
    this.setDatalist(data);
  }
  componentDidMount = () => {
    try {
      const { receptor } = this.props.location.state;
      var habiNacionales = [];
      var a_habiNacionales = this.props.location.state.habiNacionales;
      var shabiNacionales = _.split(a_habiNacionales, "-");
      for (let i = 0; i <= shabiNacionales.length - 1; i++)
        habiNacionales.push(shabiNacionales[i] === "1");
      var urlavt = this.props.location.state.avatar;
      var avatar;
      if (urlavt.search("https") === -1) {
        if (urlavt !== "undefined")
          avatar =
            urlavt === "0"
              ? ""
              : "https://firebasestorage.googleapis.com/v0/b/data-avatar-d75e7.appspot.com/o/" +
                urlavt +
                "?alt=media";
        else avatar = "0";
      } else avatar = urlavt;

      if (avatar !== "0") this.setState({ avatar });
      const {saldo,id,tipoprinter, paramterImp, portImp ,pago}=this.props.location.state
      this.setState({
        id,
        receptor,
        tipoParlay: this.props.location.state.tipoParlay,
        iu: this.props.location.state.iu,
        moneda: this.props.location.state.unidad,
        saldo,
        pago,
        activoLogin: this.props.location.state.activo,
        impriTK: this.props.location.state.impriTK,
        tipo: this.props.location.state.tipo,
        access: _.split(this.props.location.state.access, "-"),
        habiNacionales,
        tipoprinter,
        paramterImp,
        portImp,
      });
      store.dispatch({ type: "StateSaldo", saldo });
      store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
      this.querycallsaldoFn( {iu:id},this.thisuseUpdateData );
    } catch (err) {
      this.props.history.replace("/");
    }
    document.addEventListener("keydown", this.escFunction, false);
    store.dispatch({ type: "BLOCK" });
    store.dispatch({ type: "IN_data_displayBanner", displayBanner: false });

  };


  StateAvatar = (Newavatar) => {
    var { avatar } = this.state;
    if (!_.isUndefined(Newavatar) && Newavatar !== "" && Newavatar !== "0") {
      avatar =
        "https://firebasestorage.googleapis.com/v0/b/data-avatar-d75e7.appspot.com/o/" +
        Newavatar +
        "?alt=media";
    }
    this.setState({ avatar, imageHash: Date.now() });
  };
  escFunction = (event) => {
    if (event.keyCode === 27)
      if (this.state.blockesc) {
        store.dispatch({ type: "IN_BLOCK_IMP", blockesc: false });
        this.aceptarApuestafn();
      }
  };
  componentDidUpdate = (prevProps) => {
    if (!this.state.stateAp)
      if (!_.isUndefined(this.refs.aceptar_Input)) {
        this.refs.aceptar_Input.focus();
      }
  };
  componentWillUpdate = (nextProps, nextState) => {
    if (nextState.statePross !== this.state.statePross) {

      // this.querycallsaldoFn( this.state.id);
      
      this.setState({ logonNo: false });
      this.setState({ imageHash: Date.now() });
    }
  };
  setChgBlock = (stateBtn, stateAp) => {
    this.setState({
      stateBtn,
      stateAp,
      loading: false,
    });
  };

  setDatalist = ({saldo,id}) => {
    if (saldo !== -1){ this.setState({ saldo });  store.dispatch({ type: "StateSaldo", saldo });}
    this.setState({ iu: id });
  };
  changePross = () => {
    this.setState({ statePross: moment().unix() });
  };
  toggle = (stCol) => {
    if (this.state.opviewTK === 8) stCol = false;
    this.setState({
      collapsed: stCol,
      vertk: stCol,
    });
  };
  autocalculo = (logros) => {
    this.calcuParlay(this.state.apuesta, logros);
  };
  calcuParlay = (ap, logros) => {
    var valorf = 0;
    var valorini = 0;
    var valocc1;
    var factor;
    //var logros=this.state.logros;
    // ********************  Calculo del Parlay ***********************************
    logros.forEach((value) => {
      valocc1 = _.toNumber(value);
      if (valocc1 < 0) {
        valorf = valocc1 * -1; // Factor  <0 100/Logro + 1 * Apuesta
        factor = 100 / valorf + 1;
        ap = factor * ap;
        valorini = ap;
      } else {
        factor = valocc1 / 100 + 1; // Factor  >0 Logro/100 + 1 * Apuesta
        ap = factor * ap;
        valorini = ap;
      }
    });
    this.setState({ premioParley: valorini });
    //*********************************************************************************
  };
  changeviewAp = (displayAp, displayAp1, vertk) => {
    this.setState({ displayAp, displayAp1, vertk });
  };

  viewResultadoCarrNac = (regis, name, IDCN) => {
    this.setState({
      context: (
        <CloudScoreNacforCarr
          Registo={regis}
          Name={name}
          IDCN={IDCN}
          tthis={this}
        />
      ),
    });
  };
  changedMenu = (titulo, op) => {
    var {  receptor } = this.state;
    // if (this.state.activoLogin===1){

    switch (op) {
      
      case 3:
        store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
        this.setState({
          titulo,
          opviewTK: 3,
          displayAp: false,
          displayAp1: true,
          currentSub2: ["1"],
          context: (
            <Cloudnacionales
              receptor={receptor}
              virtualTicketNac={this.virtualTicketNac.bind(this)}
              changeviewAp={this.changeviewAp.bind(this)}
              onChangeMenuPrin={this.onChangeMenuPrin.bind(this)}
              ref={this.child}
              tthis={this}
            />
          ),
        });
        break;
      case 8:
        this.setState({
          titulo,
          opviewTK: 8,
          displayAp: false,
          displayAp1: false,
          vertk: false,
          currentSub2: ["1"],
          context: <CloudReportes tthis={this} />,
        });
        break;
      case 5:
        this.setState({
          visible: true,
          tituloIU: titulo,
          tm: 1,
          ModeRedux: false,
          contextIU: (
            <Cloudmisticket
              tthis={this}
              tm={this.state}
              tmTimer={this.tmTimer.bind(this)}
            />
          ),
        });
        break;
      case 6:
        this.setState({
          visible: true,
          tituloIU: titulo,
          ModeRedux: true,
          contextIU: <Cloudmisdatos tthis={this} />,
        });
        break;
      case 9:
        this.setState({
          visible: true,
          tituloIU: titulo,
          ModeRedux: false,
          contextIU: <CloudTransacc tthis={this} />,
        });
        break;
      case 10:
        this.setState({
          visible: true,
          tituloIU: titulo,
          tm: 1,
          ModeRedux: false,
          contextIU: (
            <CloudmonitorPolla
              onChildNacionaes={this.onChildNacionaes.bind(this)}
              tthis={this}
              tm={this.state}
              tmTimer={this.tmTimer.bind(this)}
              unixMomemt={moment().unix()}
            />
          ),
        });
        break;
      case 11:
        this.setState({
          visible: true,
          tituloIU: titulo,
          tm: 1,
          ModeRedux: false,
          contextIU: (
            <CloudSelecPolla
              onChildNacionaes={this.onChildNacionaes.bind(this)}
              tthis={this}
              tm={this.state}
              tmTimer={this.tmTimer.bind(this)}
              unixMomemt={moment().unix()}
            />
          ),
        });
        break;
      case 12:
        this.setState({
          visible: true,
          tituloIU: titulo,
          tm: 1,
          ModeRedux: false,
          contextIU: (
            <CloudRecomePolla
              onChildNacionaes={this.onChildNacionaes.bind(this)}
              tthis={this}
              tm={this.state}
              tmTimer={this.tmTimer.bind(this)}
              unixMomemt={moment().unix()}
            />
          ),
        });
        break;

      default:
        this.setState({
          titulo: titulo,
          context: "",
        });
    }

    // }else{
    //     notification.info({message: 'Sin Autorizacion',
    //     description: 'Apesar que usted creo su cuenta via Registro propio, debe ponerse en contacto con la oficina a fin de habilitar su usuario, Disculpe las molestias',})
    // }
  };

  /////// Accion remota Reder para NACIONLES //////
  onChildNacionaes = (option) => {
    switch (option) {
      case 1:
        this.child.current.propsActualizar(); /// Obligar la Rederizacion del Hijo en Nacionales Solo Polla

        break;

      default:
        break;
    }
  };

  onChangeMenuPrin = (titulo, op) => {
    this.changedMenu(titulo, op);
  };

  virtualTicket = (someArg, logros, acodeDB) => {
    this.setState({ statePross: moment().unix() });
    this.setState({ miTicket: someArg, logros: logros, acodeDB });
  };
  virtualTicketAme = (someArg, americana, apuesta, premioParley) => {
    this.setState({ statePross: moment().unix() });
    store.dispatch({ type: "IN_DATA_APUESTA", apuesta });
    this.setState({ miTicketAmericana: someArg, americana, premioParley });
  };
  virtualTicketNac = (someArg, nacionales, apuesta, premioParley) => {
    this.setState({ statePross: moment().unix() });
    store.dispatch({ type: "IN_DATA_APUESTA", apuesta });
    this.setState({ miTicketNacionales: someArg, nacionales, premioParley });
  };
  virtualTicketLot = (someArg, loteria, apuesta, premioParley) => {
    this.setState({ statePross: moment().unix() });
    store.dispatch({ type: "IN_DATA_APUESTA", apuesta });
    this.setState({ miTicketLoteria: someArg, loteria, premioParley });
  };

  pullGame = (id) => {
    var miTicket;
    switch (this.state.opviewTK) {
      case 1:{
        miTicket = this.state.miTicket;
        var logros = this.state.logros;
        var acodeDB = this.state.acodeDB;
        var idx = -1;
        miTicket.forEach((value, index) => {
          if (value[0].id === id) idx = index;
        });
        _.pullAt(logros, idx);
        _.pullAt(acodeDB, idx);
        _.remove(miTicket, function (n) {
          return n[0].id === id;
        });
        this.setState({
          miTicket: miTicket,
          acodeDB,
        });
        var checkAP = this.child.current.state.checkAP;
        _.pull(checkAP, id);
        this.child.current.actualizarValores(5, checkAP, 0);
        try {
          if (document.getElementById(id).getAttribute("bord") === "1")
            document.getElementById(id).setAttribute("class", "cellOdd");
          else document.getElementById(id).setAttribute("class", "cellOddBt");
        } catch (err) {}
        this.calcuParlay(this.state.apuesta, logros);
        break;
        }
      case 2:{
        this.child.current.initCheckTk(id);
        miTicket = this.state.miTicketAmericana;
        var { americana } = this.state;
        let apuesta = 0;
        _.remove(miTicket, function (n) {
          return n[0].id === id;
        });
        _.remove(americana, function (n) {
          return n.ln === id;
        });
        miTicket.forEach((value) => {
          apuesta += value[0].colum;
        });
        this.setState({
          miTicketAmericana: miTicket,
          americana,
          premioParley: 0,
        });
        store.dispatch({ type: "IN_DATA_APUESTA", apuesta });
        break;
        }
      case 3:{
        this.child.current.initCheckTk(id);
        miTicket = this.state.miTicketNacionales;
        var { nacionales } = this.state;
        let apuesta = 0;
        _.remove(miTicket, function (n) {
          return n[0].id === id;
        });
        _.remove(nacionales, function (n) {
          return n.ln === id;
        });
        miTicket.forEach((value) => {
          apuesta += value[0].colum;
        });
        this.setState({
          miTicketNacionales: miTicket,
          nacionales,
          premioParley: 0,
        });
        store.dispatch({ type: "IN_DATA_APUESTA", apuesta });
        break;
      }
      case 4:{
        this.child.current.initCheckTk(id);
        miTicket = this.state.miTicketLoteria;
        var { loteria } = this.state;
        _.remove(miTicket, function (n) {
          return n[0].id === id;
        });
        _.remove(loteria, function (n) {
          return n.ln === id;
        });

        this.setState({
          miTicketLoteria: miTicket,
          loteria,
        });
        //store.dispatch({ type: 'IN_DATA_APUESTA',apuesta});
        break;
        }
        default:
    }
  };

  pullAll = () => {
    var miTicket;
    switch (this.state.opviewTK) {
      case 1:
        miTicket = this.state.miTicket;
        if (miTicket.length !== 0) {
          this.setState({
            miTicket: [],
            logros: [],
            acodeDB: [],
          });
          var checkAP = this.child.current.state.checkAP;
          try {
            checkAP.forEach((value) => {
              if (document.getElementById(value).getAttribute("bord") === "1")
                document.getElementById(value).setAttribute("class", "cellOdd");
              else
                document
                  .getElementById(value)
                  .setAttribute("class", "cellOddBt");
            });
          } catch (err) {}
          checkAP = [];
          this.child.current.actualizarValores(5, checkAP, 0);
        }
        break;
      case 2:
        this.child.current.initCheckTk(-1);
        miTicket = this.state.miTicketAmericana;
        if (miTicket.length !== 0) {
          this.setState({
            miTicketAmericana: [],
            americana: [],
            premioParley: 0,
          });
          store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
        }
        break;
      case 3:
        this.child.current.initCheckTk(-1);
        miTicket = this.state.miTicketNacionales;
        if (miTicket.length !== 0) {
          this.setState({
            miTicketNacionales: [],
            nacionales: [],
            premioParley: 0,
          });
          store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
        }
        break;
      case 4:
        this.child.current.initCheckTk(-1);
        miTicket = this.state.miTicketLoteria;
        if (miTicket.length !== 0) {
          this.setState({
            miTicketLoteria: [],
            loteria: [],
          });
        }

        break;
        default:
    }
  };
  iClearDatosAme = (code) => {
    this.child.current.iClearDatos(code);
    this.pullAll();
  };

  onChange = (e) => {
    var vMax = "10000000000";
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (
      (!isNaN(value) && reg.test(value) && value.length <= vMax.length) ||
      value === ""
    ) {
      store.dispatch({ type: "IN_DATA_APUESTA", apuesta: value });
      switch (this.state.opviewTK) {
        case 1:
          this.calcuParlay(e.target.value, this.state.logros);
          break;
        case 2:
          this.calculoPool(e.target.value);
          break;
        case 3:
          this.calculoPoolNac(e.target.value);
          break;
        default:
      }
    }
  };
  calculoPool = (ap) => {
    var { americana } = this.state;
    var factor = americana[0].fm;
    this.setState({ premioParley: ap * factor });
  };
  calculoPoolNac = (ap) => {
    var { nacionales } = this.state;
    var factor = nacionales[0].fm;
    this.setState({ premioParley: ap * factor });
  };
  onCloseDrawer = (visibleDrawer) => {
    this.setState({ visibleDrawer });
  };
  ontickeView = () => {
    const { displayAp,displayAp1,opviewTK } = this.state;
    const hadlenOpviewTicket = {
      1:{mticke:this.state.miTicket,displa1:true,text1 : "Valor de la(s) apuesta(s):",text2 : "Posible Premio:",valColm : "160px"},
      2:{mticke:this.state.miTicketAmericana,displa1:false,text1 : "Total Apuesta:",text2 : "Total Apuesta:",valColm : "300px"},
      3:{mticke:this.state.miTicketNacionales,displa1:false,text1 : "Total Apuesta:",text2 : "Total Apuesta:",valColm : "300px"},
      4:{mticke:this.state.miTicketLoteria,displa1:false,text1 : "Total Apuesta:",text2 : "Total Apuesta:",valColm : "300px"}
    }
    const {mticke,displa1,text1,text2,valColm} = hadlenOpviewTicket[opviewTK]? hadlenOpviewTicket[opviewTK]:{mticke:[],displa1:[],text1:'',text2:'',valColm:'300px'}
    
    return (
      <div
        key="Un01"
        className={this.state.logonNo ? "ontickeView faceShake" : "ontickeView"}
      >
        <div className="ontickeViewlabel">
          <label>Tu Ticket</label>
        </div>
        <br />
        <div className="divButtomVerTk">
          <Button
            block
            icon="eye"
            className="ant-btn-block2"
            onClick={() => this.onCloseDrawer(true)}
          >
            {" "}
            Ver Ticket's{" "}
          </Button>
        </div>
        <div className="divButtomEliminar">
          <Button
            ghost
            block
            icon="close"
            className="ant-btn2 ant-btn-background-ghost2"
            onClick={() => this.pullAll()}
          >
            {" "}
            Eliminar Todo{" "}
          </Button>
        </div>
        {displayAp ? (
          <div className="onInputApuesta">
            <Input
              placeholder="Tu Apuesta"
              ref="aceptar_Input"
              value={this.state.apuesta === 0 ? "" : this.state.apuesta}
              onChange={(e) => this.onChange(e)}
              disabled={this.state.stateAp}
            />
          </div>
        ) : (
          ""
        )}
        <div key="Un02">
          {mticke.map((value, index) => {
            var xvalue = value[0];
            return (
              <div key={"tk" + index}>
                <div
                  key={"1-" + index}
                  style={{
                    width: "260px",
                    float: "left",
                    background: index % 2 === 1 ? "#35352a" : "#35352a",
                    color: "#c1c4cd",
                    paddingRight: "32px",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                  }}
                >
                  <div
                    key={"2-" + index}
                    style={{
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#c1c4cd",
                      display: "table-cell",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "10px",
                        width: "30px",
                        height: "20px",
                      }}
                    >
                      {" "}
                      <Icon
                        type="close"
                        theme="outlined"
                        style={{ fontSize: "9px" }}
                        onClick={() => this.pullGame(xvalue.id)}
                      />{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      width: valColm,
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#ffdf1b",
                      display: "table-cell",
                      paddingRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    {xvalue.lineA.split("/n").map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#ffdf1b",
                      display: "table-cell",
                      paddingRight: "10px",
                      fontSize: "13px",
                      alignItems: "right",
                    }}
                  >
                    {xvalue.colum}
                  </div>
                </div>
                <div
                  key={"3-" + index}
                  style={{
                    width: "260px",
                    float: "left",
                    background: index % 2 === 1 ? "#35352a" : "#35352a",
                    color: "#c1c4cd",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                  }}
                >
                  <div
                    key={"4-" + index}
                    style={{
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#c1c4cd",
                      display: "table-cell",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "10px",
                        width: "30px",
                        height: "20px",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "160px",
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#7c8292",
                      display: "table-cell",
                      paddingRight: "0px",
                      fontSize: "12px",
                    }}
                  >
                    {xvalue.lineB}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#7c8292",
                      display: "table-cell",
                      paddingRight: "10px",
                      fontSize: "12px",
                      alignItems: "right",
                    }}
                  ></div>
                </div>
                <div
                  key={"5-" + index}
                  style={{
                    width: "260px",
                    float: "left",
                    background: index % 2 === 1 ? "#35352a" : "#35352a",
                    color: "#c1c4cd",
                    paddingRight: "32px",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                    borderBottom: "2px solid #000",
                  }}
                >
                  <div
                    key={"6-" + index}
                    style={{
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#c1c4cd",
                      display: "table-cell",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "10px",
                        width: "30px",
                        height: "20px",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "160px",
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#c1c4cd",
                      display: "table-cell",
                      paddingRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {xvalue.lineC}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      background: index % 2 === 1 ? "#35352a" : "#35352a",
                      color: "#c1c4cd",
                      display: "table-cell",
                      paddingRight: "10px",
                      fontSize: "12px",
                      alignItems: "right",
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {displayAp1 && (
            <div
              style={{
                width: "260px",
                float: "left",
                background: "#585858",
                color: "#FFF",
                paddingRight: "32px",
                paddingLeft: "10px",
                paddingTop: "3px",
              }}
            >
              <div
                style={{
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                }}
              >
                <div
                  style={{
                    paddingRight: "10px",
                    width: "30px",
                    height: "20px",
                  }}
                >
                  {" "}
                </div>
              </div>
              <div
                style={{
                  width: "160px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "11px",
                }}
              >
                {text1}
              </div>
              <div
                style={{
                  width: "100px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "14px",
                  alignItems: "right",
                }}
              >
                <span style={{ color: "#ffdf1b" }}>
                  <FormattedNumber
                     value={this.state.apuesta}
                     stl="currency"
                     currency={this.state.moneda}
                    />
                </span>
              </div>
            </div>
          )}
          {displayAp && (
            <div
              style={{
                width: "260px",
                float: "left",
                background: "#585858",
                color: "#FFF",
                paddingRight: "32px",
                paddingLeft: "10px",
                paddingTop: "3px",
              }}
            >
              <div
                style={{
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                }}
              >
                <div
                  style={{
                    paddingRight: "10px",
                    width: "30px",
                    height: "20px",
                  }}
                >
                  {" "}
                </div>
              </div>
              <div
                style={{
                  width: "160px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "11px",
                }}
              >
                {text2}
              </div>
              <div
                style={{
                  width: "100px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "14px",
                  alignItems: "right",
                }}
              >
                <span style={{ color: "#ffdf1b" }}>
                  <FormattedNumber
                    value={this.state.premioParley}
                    stl="currency"
                    currency={this.state.moneda}
                  />
                </span>
              </div>
            </div>
          )}
          {displa1 && (
            <div
              style={{
                width: "260px",
                float: "left",
                background: "#585858",
                color: "#FFF",
                paddingRight: "32px",
                paddingLeft: "10px",
                paddingTop: "3px",
                borderBottom: "1px solid #85929e",
              }}
            >
              <div
                style={{
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                }}
              >
                <div
                  style={{
                    paddingRight: "10px",
                    width: "30px",
                    height: "20px",
                  }}
                >
                  {" "}
                </div>
              </div>
              <div
                style={{
                  width: "160px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "11px",
                }}
              >
                Posible Ganacia:
              </div>
              <div
                style={{
                  width: "100px",
                  background: "#585858",
                  color: "#FFF",
                  display: "table-cell",
                  paddingRight: "10px",
                  fontSize: "14px",
                  alignItems: "right",
                }}
              >
                <span style={{ color: "#ffdf1b" }}>
                  <FormattedNumber
                    value={this.state.premioParley - this.state.apuesta}
                    stl="currency"
                    currency={this.state.moneda}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div style={{ background: "#14805e", width: "260px", border: "0px" }}>
          <Button
            ghost
            block
            className="ant-btn1 ant-btn-background-ghost1"
            disabled={this.state.stateBtn}
            onClick={() => this.aceptarApuestafn()}
          >
            Aceptar la apuesta (ESC)
          </Button>
        </div>
        <br />
      </div>
    );
  };
  /////////////////////////
  aceptarApuestafn = () => {
    var mticke = [],
      opviewTK;
    switch (this.state.opviewTK) {
      case 1:
        mticke = this.state.miTicket;
        break;
      case 2:
        mticke = this.state.miTicketAmericana;
        break;
      case 3:
        const { receptor, nacionales } = this.state;
        if (nacionales.length) {
          if (receptor) {
            const { tipo } = nacionales[0];
            if (tipo === 8) {
                if (this.child.current){
                  const { nombredePolla } = this.child.current.state;
                  if (nombredePolla === "") {
                    notification.error({
                      message: "Impresion de Ticket",
                      description:
                        "Usted es un Usuario RECEPTOR, y la Polla Necesita el Nombre de CLIENTE!",
                    });
                    if (this.child.current.refInput.current)
                      this.child.current.refInput.current.focus()
                    break;
                  }
                }
            }
          }
          mticke = this.state.miTicketNacionales;
        }
        break;
      case 4:
        mticke = this.state.miTicketLoteria;
        break;
      default:
    }
    if (mticke.length !== 0) {
      this.setChgBlock(true, true);
      this.setState({ statePross: moment().unix(), loading: true });
      var actuar = false;
      var recordnew = [];
      var IDCN 
      var carr 
      var tipo 
      switch (this.state.opviewTK) {
        case 1:
          if (
            parseFloat(this.state.apuesta) !== 0 &&
            this.state.apuesta !== ""
          ) {
            actuar = true;
            recordnew = {
              jng: _.join(this.state.acodeDB, "*"),
              iu: this.state.id,
              ap: this.state.apuesta,
              ip: ip.address(),
            };
          }
          opviewTK = 1;
          break;
        case 2:
          this.child.current.initCheckTk(-1);
          var { americana } = this.state;
          IDCN = americana[0].IDCN;
          carr = americana[0].carr;
          tipo = americana[0].tipo;
          if (tipo === 1) {
            actuar = true;
            var jnd = [];
            var ce = this.state.activoView.ce;
            var ide;
            var sumAP = 0;
            americana = _.orderBy(americana, ["eje"], ["asc"]);
            for (let i = 1; i <= ce; i++) {
              ide = americana.findIndex( (o) =>  o.eje === i)
              if (ide === -1) jnd.push("0-0-0");
              else {
                jnd.push(
                  americana[ide].w +
                    "-" +
                    americana[ide].p +
                    "-" +
                    americana[ide].s
                );
                sumAP +=
                  parseInt(americana[ide].w) +
                  parseInt(americana[ide].p) +
                  parseInt(americana[ide].s);
              }
            }
            recordnew = {
              jng: _.join(jnd, "|"),
              iu: this.state.id,
              ap: sumAP,
              ip: ip.address(),
              carr: carr,
              idcn: IDCN,
              tipo: 0,
            };
          }
          if (tipo >= 2 && tipo <= 4) {
            actuar = true;
            var pool = americana[0].pool;
            var jng = [];
            var ejem = [];
            var poss = pool[0].pos;
            for (var i = 0; i <= pool.length - 1; i++) {
              if (poss === pool[i].pos) ejem.push(pool[i].eje);
              else {
                jng.push(_.join(ejem, "-") + "-");
                ejem = [];
                ejem.push(pool[i].eje);
                poss = pool[i].pos;
              }
            }
            jng.push(_.join(ejem, "-") + "-");
            recordnew = {
              jng: "|" + _.join(jng, "|"),
              iu: this.state.id,
              ap: this.state.apuesta,
              ip: ip.address(),
              carr: carr,
              idcn: IDCN,
              tipo: tipo - 1,
            };
          }
          opviewTK = 2;

          break;
        case 3:
          const { nombredePolla } = this.child.current.state;
          this.child.current.initCheckTk(-1);
          var { nacionales } = this.state;
          IDCN = nacionales[0].IDCN;
          carr = nacionales[0].carr;
          tipo = nacionales[0].tipo;
          if (tipo === 8) {
            var { pool:nPool } = nacionales[0];
            let jnd = [];
            nPool.forEach(({eje})=>{jnd.push(eje)})
            // for (let i = 0; i <= nPool.length - 1; i++) {
            //   var { eje } = nPool[i];
            //   jnd.push(eje);
            // }
            recordnew = {
              jng: jnd.join( "|"),
              iu: this.state.id,
              ap: this.state.apuesta,
              ip: ip.address(),
              carr: 0,
              idcn: IDCN,
              tipo: 8,
              nombredePolla,
            };
            actuar = true;
            opviewTK = 6;

            break;
          }
          if (tipo === 1) {
            actuar = true;
            let jnd = [];
            let {ce} = this.child.current.state.activoView;
            nacionales = _.orderBy(nacionales, ["eje"], ["asc"]);
            for (let i = 1; i <= ce; i++) {
             const ide = _.findIndex(nacionales, function (o) {
                return o.eje === i;
              });
              if (ide === -1) jnd.push("0");
              else jnd.push(nacionales[ide].w);
            }
            recordnew = {
              jng: jnd.join( "|"),
              iu: this.state.id,
              ap: this.state.apuesta,
              ip: ip.address(),
              carr: carr,
              idcn: IDCN,
              tipo: 0,
            };
          }
          if (tipo >= 2 && tipo <= 7) {
            actuar = true;
            let {pool} = nacionales[0];
            let jng = [];
            let ejem = [];
            let poss = pool[0].pos;

            for (let i = 0; i <= pool.length - 1; i++) {
              if (poss === pool[i].pos) ejem.push(pool[i].eje);
              else {
                jng.push(`${ejem.join("-")}-`);
                ejem = [];
                ejem.push(pool[i].eje);
                poss = pool[i].pos;
              }
            }

            jng.push(`${ejem.join("-")}-`);
            recordnew = {
              jng: `|${jng.join("|")}`,
              iu: this.state.id,
              ap: this.state.apuesta,
              ip: ip.address(),
              carr: carr,
              idcn: IDCN,
              tipo: tipo - 1,
            };
          }
          opviewTK = 3;
          break;
        case 4:
          let total = 0;
          let { loteria } = this.state;
          this.child.current.initCheckTk(-1);
          /// Valores del objeto Loteria, descripcion de contenidos...
          //cmd:,numer:,ln:,tipo:,mnt:,lot:,hor:
          /// cmd:COMODIN (Aries,Virgo..Etc)
          /// numer: Numero comprados
          /// ln:    Linea
          /// tipo:  1=Normal, 2=Adicionales
          /// mnt: Apuesta del numero
          /// lot: Numero de Loteria (posicion en el JSON)
          /// hor: Horario de Sorteo
          /// cnt: Cantidad de Numeros en numer
          /// ida_sel: Es id del adicional
          /// idlot:   idl id de loteria
          /// sor: Sorteo
          let rdata = [];
          for (let i in loteria) {
            const {
              numer, cmd,tipo,mnt,hor,cnt,ida_sel,idlot,sor,
            } = loteria[i];
            const data = {
              numer,cmd,
              tipo,mnt,
              hor,
              lot: idlot,
              ida: ida_sel,
              sor,
            };
            rdata.push(data);
            total += mnt * cnt;
            actuar = true;
          }
          recordnew = {
            jng: JSON.stringify(rdata),
            iu: this.state.id,
            ap: total,
            ip: ip.address(),
            carr: 0,
            idcn: 0,
            tipo: 0,
          };
          opviewTK = 5;

          break;
          default:
      }
      this.setState({ subopview: tipo });
      if (actuar)
        this.mutationRegistrarApuestaFn(recordnew, opviewTK, this.state.id);
      else {
        this.setChgBlock(false, false);
        if (opviewTK === 1 || opviewTK === 2) {
          notification.error({
            message: "Impresion de Ticket",
            description: "No coloco el monto para hacer su apuesta!",
          });
          if (this.state.displayAp) this.refs.aceptar_Input.focus();
        }
        store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
      }
      /// Graba la Transsaccion
    } else this.setState({ logonNo: true });

    store.dispatch({ type: "IN_BLOCK_IMP", blockesc: true });
  };

  notificacion_lot = async (extraidos) => {
    await this.queryrsmJndLtFn(extraidos, 0, true);
    Modal.warning({
      title: "Apuesta(s) no aceptadas o modificadas",
      content: (
        <Table
          rowKey={(record) => record.ln}
          columns={this.state.colums_noti}
          dataSource={this.state.data_noti}
        />
      ),
    });
  };

  aprobacion_lot = async (extraidos, monto) => {
    var loteria = JSON.parse(extraidos);

    this.setState({ loteria });
    await this.queryrsmJndLtFn(extraidos, monto, false);
  };
  aprobarTicket = () => {
    this.setState({ visPreapor: false, opviewTK: 4 });
    this.aceptarApuestafn();
  };
  CancelarTicket = () => {
    this.setState({ visPreapor: false });
  };

  inciarVarlores = (saldo, serialImp) => {
    var { visibleprint, vsbprt } = this.state;
    if (this.state.impriTK === 1) {
      visibleprint = true;
      vsbprt = true;
    }
    this.setState({
      premioParley: 0,
      saldo,
      serialImp,
      visibleprint,
    });
    store.dispatch({ type: "ACTV_PRN", vsbprt });
    store.dispatch({ type: "IN_DATA_APUESTA", apuesta: 0 });
    store.dispatch({ type: "StateSaldo", saldo });
    this.pullAll();
  };

  whoisDato = (storeData) => {
    var acodeDB = this.state.acodeDB;
    var miTicket = this.state.miTicket;
    var deCompre = _.split(storeData, "|");
    var deCompre1 = _.split(deCompre[0], "%");
    var dataReal = _.split(deCompre1[0], "-");
    var equipoR = dataReal[0];
    var apuestaR = dataReal[1];
    var equipoC;
    var apuestaC;
    var id = -1;
    for (var i = 0; i <= acodeDB.length - 1; i++) {
      deCompre = _.split(acodeDB[i], "|");
      deCompre1 = _.split(deCompre[0], "%");
      dataReal = _.split(deCompre1[0], "-");
      equipoC = dataReal[0];
      apuestaC = dataReal[1];
      if (equipoC === equipoR && apuestaC === apuestaR) {
        id = i;
        break;
      }
    }
    if (id !== -1) {
      var dato1 = miTicket[id][0].lineA;
      var dato2 = miTicket[id][0].lineB;
      return dato1 + " " + dato2;
    } else return "-1";
  };

  Salir = () => {
    store.dispatch({ type: "UNBLOCK" });
    store.dispatch({ type: "IN_data_displayBanner", displayBanner: true });
    this.hashSet('0')
    this.props.history.replace("/");
  };
  hideModal = () => {
    this.setState({
      visible: false,
      tm: null,
    });
  };
  tmTimer = (tm) => {
    this.setState({ tm });
  };

  offprint = () => {
    this.setState({ visibleprint: false });
  };

  activateWhatsapp=(whatsapp,mensajePrint)=>{
    const ticketMensaje= mensajePrint
                          ?mensajePrint
                          :this.state.ticketMensaje

    this.setState({whatsapp,ticketMensaje})
  }
  

  changedSubMenu = (op) => {
    var { receptor } = this.state;
    switch (op) {
      case 1:
        if (this.state.opviewTK === 3)
          this.setState({
            context: (
              <Cloudnacionales
                receptor={receptor}
                virtualTicketNac={this.virtualTicketNac.bind(this)}
                changeviewAp={this.changeviewAp.bind(this)}
                ref={this.child}
                tthis={this}
              />
            ),
          });
        break;
      case 2:
  
        if (this.state.opviewTK === 3)
          this.setState({
            context: <CloudnacResumidoResul tthis={this} {...this.state} />,
            vertk: false,
          });
        break;
      default:
    }
    this.setState({ currentSub2: [_.toString(op)] });
  };
  onChangeHelp = (e) => {
    const { value } = e.target;
    this.setState({ messgCht: value });
  };
  // chartConect = () => {
  //   var { statusCht } = this.state;
  //   // if (statusCht.length === 0) {
  //   //   socket.on("connect_error", () => {
  //   //     console.log('HAY ERROR')
  //   //   });
  //   //   socket.on("disconnect", (reason) => {
  //   //     console.log(reason)
  //   //   })
  //   //   socket.emit("chatConet", this.state.id);
  //   //   socket.on(`chat_Id_${this.state.id}`, (data) => this.receptMsg(data));
     
  //   // }
  // };
  receptMsg = (data) => {
    var jsData = JSON.parse(data);
    var cantMsg = 0;
    var history = jsData.history;
    for (var rows in history) {
      if (!history[rows].readcli) cantMsg++;
    }
    this.setState({ historiCht: jsData.history, cantMsg });
    this.setState({
      statusCht: {
        conect: jsData.conecctHd,
        idHd: jsData.idHd,
        avatar: jsData.avatar,
      },
    });
    this.refs.divListtm.scrollTo(0, 5000);
  };
  chartSendMesj = () => {
    // this.chartConect();
    // var { messgCht } = this.state;
    // var { statusCht } = this.state;
    // var data = {
    //   to: this.state.id,
    //   for: statusCht.idHd,
    //   msg: messgCht,
    //   id: this.state.id,
    // };
    // socket.emit(`chatMsg`, JSON.stringify(data));
    this.setState({ messgCht: "" });
    this.refs.divListtm.scrollTo(0, 5000);
    this.refs.message_input.focus();
  };
  onFocusMsg_goto = () => {
    this.refs.divListtm.scrollTo(0, 5000);
    store.dispatch({ type: "IN_ppBoxIm", ppBoxIm: 1 });
  };
  onBlurMsg_goto = () => {
    store.dispatch({ type: "IN_ppBoxIm", ppBoxIm: 0 });
  };
  visibleChange = (e) => {
    // if (e) this.chartConect();
    if (!_.isUndefined(this.refs.message_input))
      this.refs.message_input.focus();
  };

  responsibeWith = (xminWidth) => {
    var widthx = window.innerWidth;
    return widthx <= xminWidth;
  };

  onClickVt = () => {
    var { verBtnTk } = this.state;
    this.setState({ verBtnTk: !verBtnTk });
  };

  render() {
    const { statusCht, access, opviewTK, verBtnTk, ModeRedux } = this.state;
    const mthelp = (
      <div style={{ height: 300, width: 250 }}>
        <div
          ref="divListtm"
          style={{ height: 240, width: "100%", overflow: "auto" }}
        >
          <List
            dataSource={this.state.historiCht}
            renderItem={(item) => (
              <List.Item
                className={
                  item.to !== this.state.id
                    ? "divListtmItem"
                    : "divListtmItem_blc"
                }
              >
                <List.Item.Meta
                  avatar={
                    item.to === this.state.id ? (
                      <Avatar
                        style={{
                          backgroundColor: "#1890FF",
                          verticalAlign: "middle",
                        }}
                        size={32}
                      >
                        Tu
                      </Avatar>
                    ) : (
                      <Avatar
                        size={32}
                        icon="user"
                        src={`${this.state.url + item.avatar + "?alt=media"}&${
                          this.state.imageHash
                        }`}
                      />
                    )
                  }
                  // title={item.totext}
                  title={
                    !_.isUndefined(item.time)
                      ? moment.unix(item.time, "MMM D YYYY, h:mm a").fromNow()
                      : ""
                  }
                  description={item.text}
                />
              </List.Item>
            )}
          ></List>
        </div>

        <Row>
          <Col span={16}>
            <TextArea
              autosize
              value={this.state.messgCht}
              style={{ width: "100%" }}
              ref="message_input"
              onChange={(e) => this.onChangeHelp(e)} //onPressEnter={(e)=>{this.chartSendMesj(e);}}
              onFocus={() => this.onFocusMsg_goto()}
              onBlur={() => this.onBlurMsg_goto()}
              placeholder="Mensaje"
            />
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={() => this.chartSendMesj()}>
              Enviar
            </Button>
          </Col>
        </Row>
      </div>
    );
    return (

          <Spin spinning={this.state.loading} delay={500}>
            <Layout style={{ minHeight: "100vh", background: "#2c3e50" }}>
              <Sider
                trigger={null}
                collapsed={true}
                onMouseOver={() => this.toggle(false)}
                onMouseOut={() => this.toggle(true)}
              >
                <div style={{ marginLeft: "10px" }}>
                  {this.state.collapsed ? (
                    <div>
                      <label style={styles.textTitulo1}>CGG</label>
                    </div>
                  ) : (
                    <div>
                      <label style={styles.textTitulo1}>CG</label>
                      <label style={styles.textTitulo2}>Group</label>
                      
                    </div>
                  )}
                  {this.responsibeWith(850) ? (
                    <Button type="primary" onClick={() => this.onClickVt()}>
                      Ver Ticket
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <Menu theme="dark" mode="inline">
            
                  {access[2] === "1" && (
                    <Menu.Item
                      key="3"
                      onClick={() =>
                        this.onChangeMenuPrin("Polla", 3)
                      }
                    >
                      <MyIcon
                        type="icon-icons-horseshoe"
                        style={{ fontSize: "25px" }}
                      />
                      <span>Polla</span>
                    </Menu.Item>
                  )}
              
                  <Menu.Item
                    key="5"
                    onClick={() => this.onChangeMenuPrin("Ver Mis Ticket,s", 5)}
                  >
                    <Icon
                      type="ordered-list"
                      theme="outlined"
                      style={{ fontSize: "25px" }}
                    />
                    <span>Ver Mis Tickets</span>
                  </Menu.Item>
                  {this.state.tipo === 3 ? (
                    <Menu.Item
                      key="8"
                      onClick={() => this.onChangeMenuPrin("Reportes", 8)}
                    >
                      <Icon
                        type="exception"
                        theme="outlined"
                        style={{ fontSize: "25px" }}
                      />
                      <span>Reportes </span>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  <Menu.Item
                    key="9"
                    onClick={() => this.onChangeMenuPrin("Transacciones", 9)}
                  >
                    <Icon type="sliders" style={{ fontSize: "25px" }} />
                    <span>Transacciones</span>
                  </Menu.Item>
                  <Menu.Item
                    key="6"
                    onClick={() => this.onChangeMenuPrin("Opciones", 6)}
                  >
                    <MyIcon type="icon-Setup" style={{ fontSize: "25px" }} />
                    <span>Opciones</span>
                  </Menu.Item>

                  <Menu.Item key="7" onClick={() => this.Salir()}>
                    <MyIcon type="icon-ExitIcon" style={{ fontSize: "25px" }} />
                    <span>Salir</span>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout>
                <Header
                  style={{
                    background: " #001529",
                    height: this.responsibeWith(850) ? "145px" : "100px",
                  }}
                >
                  <Row>
                    <Col md={15} xs={5}>
                      <div style={styles.contHead2}>
                        {opviewTK !== -1 && (
                          <Menu
                            visible={this.state.titulo !== ""}
                            mode={
                              this.responsibeWith(850)
                                ? "vertical"
                                : "horizontal"
                            }
                            theme="dark"
                            className="select-menu-prin"
                            defaultSelectedKeys={["1"]}
                            selectedKeys={this.state.currentSub2}
                          >
                            <Menu.Item
                              key="1"
                              onClick={() => this.changedSubMenu(1)}
                            >
                              <Icon type="down" theme="outlined" />
                              {this.state.titulo}
                            </Menu.Item>
                            {opviewTK !== 8 ? (
                              <Menu.Item
                                key="2"
                                onClick={() => this.changedSubMenu(2)}
                              >
                                <Icon type="down" theme="outlined" />
                                Resultados
                              </Menu.Item>
                            ) : (
                              ""
                            )}
                            {opviewTK === 2 && (
                              <Menu.Item
                                key="3"
                                onClick={() => this.changedSubMenu(3)}
                              >
                                <Icon type="down" theme="outlined" />
                                Retrospecto
                              </Menu.Item>
                            )}
                          </Menu>
                        )}
                      </div>
                    </Col>
                    <Col span={9}>
                      <Row align="middle">
                        {/* <Col
                          xl={2}
                          xs={0}
                          sm={0}
                          md={0}
                          offset={1}
                          style={{ marginTop: "20px", marginLeft: "20px" }}
                        >
                          <Tooltip title={this.state.iu}>
                            <Avatar
                              size={64}
                              icon="user"
                              src={`${this.state.avatar}&${this.state.imageHash}`}
                            />
                          </Tooltip>
                        </Col> */}
                        <Col span={8} >
                          <Row>
                            {" "}
                            <Col
                              style={{ height: "20px" }}
                              lg={20}
                              xs={0}
                              sm={0}
                              md={0}
                            >
                              <label style={{ color: "#fff" }}>
                                ID:{this.state.id}
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              style={{ height: "20px" }}
                              xl={24}
                              lg={0}
                              xs={0}
                              sm={0}
                              md={0}
                            >
                              <label style={{ color: "#fff" }}>
                                {this.state.iu}
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ height: "20px" }}>
                              {this.state.pago === 1 ? (
                                <label style={{ color: "#dc7633" }}>
                                  Saldo:
                                  <FormattedNumber
                                    value={this.state.saldo}
                                    stl="currency"
                                    currency={this.state.moneda}
                                  />
                                </label>
                              ) : (
                                <label style={{ color: "#dc7633" }}>
                                  PostPago({this.state.moneda})
                                </label>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Header>
                <Content style={{ background: "#2d2d2d" }}>
                  {this.state.context}
                  {this.state.context_modal}
                </Content>
              </Layout>
              <Sider
                className="sidercss"
                style={{
                  display: this.responsibeWith(850)
                    ? verBtnTk
                      ? ""
                      : "none"
                    : this.state.vertk
                    ? ""
                    : "none",
                }}
              >
                {this.ontickeView()}
              </Sider>
            </Layout>
            <Modal
              wrapClassName={
                ModeRedux ? "modal-options-view" : "modal-verticket"
              }
              title={this.state.tituloIU}
              visible={this.state.visible}
              onOk={this.hideModal}
              onCancel={this.hideModal}
              okText="OK"
              cancelText={null}
              footer={null}
            >
              {this.state.contextIU}
            </Modal>
            <Modal
              wrapClassName="modal-Imprimir"
              title="Desea Imprimir el  Ticket?"
              visible={this.state.visibleprint}
              onOk={this.offprint}
              onCancel={this.offprint}
              footer={null}
              closable={false}
              maskClosable={false}
            >
              <PrintTk
                {...this}
                serial={this.state.serialImp}
                currency={this.state.moneda}
                tthis={this}
                visibleprint={this.state.vsbprt}
              />
            </Modal>
            <Drawer
              title="Ver Ticket's"
              closable={false}
              onClose={() => this.onCloseDrawer(false)}
              visible={this.state.visibleDrawer}
              width={420}
            >
              <CloudVerticket tthis={this} />
            </Drawer>

            <Affix style={{ position: "absolute", top: "680px", left: "30px" }}>
              <Popover
                content={mthelp}
                // trigger="click"
                title={
                  statusCht.conect ? (
                    <Badge status="success" text="Soporte Conectado" />
                  ) : (
                    <Badge status="error" text="Soporte Fuera de Linea" />
                  )
                }
                ref={(popover) => (this.popover = popover)}
                onVisibleChange={(e) => this.visibleChange(e)}
              >
                <Badge count={this.state.cantMsg}>
                  {" "}
                  <Button
                    type="primary"
                    icon="message"
                    style={{ fontSize: "20px" }}
                  />{" "}
                </Badge>
              </Popover>
            </Affix>
            <Modal
              wrapClassName="modal-preaprobacion"
              title="Pre-Aprobacion del Ticket"
              visible={this.state.visPreapor}
              onOk={this.aprobarTicket}
              onCancel={this.CancelarTicket}
              okText="Aprobar"
            >
              {this.line(4, this.state.valuePreapro, 1, this.state.moneda)}
            </Modal>
            <Button onClick={()=>this.activateWhatsapp(true,'HOLA')} >click</Button>
            <Whatsapp activate={this.state.whatsapp} menssengerTxt={this.state.ticketMensaje} onchange={(e)=>this.activateWhatsapp(e,null)}/>
          </Spin>
    
    );
  }
}

const styles = {
  Avatar: {
    float: "left",
    width: "75px",
    height: "100px",
  },
  contHead1: {
    float: "right",
    width: "260px",
    lineHeight: "1.6",
  },
  contHead2: {
    textAlign: "left",
    marginTop: "50px",
    float: "left",
    width: "500px",
  },
  textTitulo1: {
    fontSize: "25px",
    color: "#FF5733",
    fontWeight: "600",
  },
  textTitulo2: {
    fontSize: "15px",
    color: "#415d76",
  },
  textTituloTk: {
    color: "#fff",
    alignItems: "center",
    float: "left",
  },
};

export default Mainapp;
